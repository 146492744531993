import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          social {
            twitter
            email
            instagram
            linkedin
          }
        }
      }
    }
  `);

  const { twitter, email, instagram, linkedin } = data.site.siteMetadata.social;

  return (
    <footer className="lg:flex uppercase justify-between pb-6 pt-6 text-base sm:text-lg">
      <div className="mb-4 md:mb-0">
        <a className=" text-claire-red" href={`mailto:${email}`}>
          {email}
        </a>
      </div>
      <div>
        <ul className="p-0 m-0">
          <li className="p-0 m-0 md:inline">
            <a href={twitter}>Twitter</a>,
          </li>
          <li className="p-0 m-0 md:inline md:ml-2">
            <a href={instagram}>Instagram</a>,
          </li>
          <li className="p-0 m-0 md:inline md:ml-2">
            <a href={linkedin}>LinkedIn</a>,
          </li>
          <li className="p-0 m-0 md:inline md:ml-2">
            <a href="http://old.clairepedersen.com">Archive</a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
