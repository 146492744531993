import React, { useContext } from "react";
import { Link } from "gatsby";
import PortfolioLayoutContext from "../context/index";
import { navigate } from "gatsby";

export default ({ uri }) => {
  const [portfolioLayout, setPortfolioLayout] = useContext(
    PortfolioLayoutContext
  );

  return (
    <nav className="z-50 uppercase flex justify-between py-3 tracking-wide fixed top-0 left-0 right-0 mx-3">
      <h1 className="m-0">
        <Link to="/" className="relative" style={{ top: "4px" }}>
          <span className="hidden md:inline">Claire Pedersen</span>
          <span className="inline md:hidden">C &mdash; P</span>
        </Link>
      </h1>

      <div className="">
        <span>
          <Link to="/about">INFO</Link>
        </span>

        <span
          className="ml-5 inline-block relative cursor-pointer"
          style={{ top: "5px", width: "30px", height: "30px" }}
          onClick={() => {
            if (uri === "/projects") {
              navigate("/");
            } else {
              navigate("/projects");
            }
          }}
        >
          {uri === "/projects" ? (
            <img className={``} src="/list.svg" />
          ) : (
            <img className={``} src="/grid.svg" />
          )}
        </span>
      </div>
    </nav>
  );
};
