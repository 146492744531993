import React from "react";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

export default ({ meta, pathname }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            siteName: title
            defaultDescription: description
          }
        }
      }
    `
  );

  const { siteName, siteUrl } = site.siteMetadata;
  const { title, description, image } = meta;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en-US",
        }}
        title={`${title}`}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: `${title}`,
          },
          {
            property: `og:url`,
            content: `${siteUrl}/${pathname}/`,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:metaImage:alt`,
            content: description,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: description,
          },
          {
            property: "og:image:secure_url",
            content: `http:${image.file.url}`,
          },
          {
            property: "og:image:url",
            content: `http:${image.file.url}`,
          },
          {
            property: "og:image",
            content: `https:${image.file.url}`,
          },
          {
            property: "og:image:width",
            content: image.file.details.image.width,
          },
          {
            property: "og:image:height",
            content: image.file.details.image.height,
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
        ]}
      ></Helmet>
    </>
  );
};
