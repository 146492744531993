import React from "react";
import Header from "./header";
import Footer from "./footer";

export default ({ children, uri }) => {
  return (
    <main className="px-3 text-lg lg:text-xl ">
      <Header uri={uri} />
      <div className="" style={{ paddingTop: "70px" }}>
        {children}
      </div>
      <Footer />
    </main>
  );
};
